<template>
    <div id="editor">
        <v-overlay v-if="loading" persistent opacity="0.9" color="white">
            <v-progress-circular color="indigo" indeterminate size="60" width="5"></v-progress-circular>
        </v-overlay>
        <v-app-bar dense clipped-right>
            <v-btn @click="$router.go(-1)" tile color="primary" height="48" elevation="0" class="ma-0 mr-1 text-center">
                <v-icon>mdi-chevron-left</v-icon> {{ $t("generic.lang_back") }}
            </v-btn>
            <v-btn-toggle borderless tile v-model="editor.mode" mandatory v-if="!toggleBackground">
                <v-btn v-for="mode in filteredModes" :key="mode.name" @click="mode.handler()" :value="mode.name" x-small
                    :color="editor.mode === mode.name ? 'indigo' : ''" class="ma-0 position-relative" height="48">
                    <v-col class="pa-0" :class="editor.mode === mode.name?'text-white':''">
                        <component v-if="mode.svgIcon" style="width:18px;height:18px;" :is="mode.icon"
                            :color="editor.mode === mode.name?'white':'black'"></component>
                        <v-icon v-else :color="editor.mode === mode.name?'white':''" size="15">{{ mode.icon }}</v-icon>
                        <v-icon :color="editor.mode === mode.name?'white':''"
                            v-if="mode.name.includes('draw') || mode.name.includes('add')" class="v-btn--has-bg"
                            style="position: absolute; top: -5px; right: -5px;z-index: 2; border-radius: 50px;"
                            size="10">mdi-plus</v-icon>
                        <div v-if="mode.label">{{ mode.label }}</div>
                    </v-col>
                </v-btn>
                <v-divider vertical></v-divider>
            </v-btn-toggle>
            <v-spacer></v-spacer>
            <div>
                <template v-if="selectedSeatsAttrs.length > 0">
                    <span>
                        {{ $t("generic.lang_selected") + ' ' + selectedSeatsAttrs.length }}
                    </span>
                    <br/>
                </template>
                <template v-else-if="seats > 0">
                    <span>{{ seats }} {{ $t("eventbee.lang_seats") }}</span>
                </template>
            </div>
            <v-spacer></v-spacer>
            <v-btn v-if="!toggleBackground" @click="togglePricing" class="mx-0" tile height="48" elevation="0"
                :color="showPricing ? 'indigo' : ''" :dark="showPricing">
                <v-col class="pa-0">
                    <v-icon size="20">mdi-currency-eur</v-icon>
                    <div class="caption">{{ $t("eventbee.lang_pricing") }}</div>
                </v-col>
            </v-btn>
            <v-btn class="mx-0" @click="toggleGrid" tile height="48" elevation="0">
                <v-icon>{{ showGrid ? 'mdi-grid-off' : 'mdi-grid' }}</v-icon>
            </v-btn>
            <v-btn class="mx-0" @click="seatingPreviewDialog = true" tile height="48" elevation="0">
                <v-icon> mdi-eye-circle-outline </v-icon>
            </v-btn>
            <v-btn class="mx-0" @click="focusOnBox" tile height="48" elevation="0">
                <v-icon>mdi-image</v-icon>
            </v-btn>
            <v-btn class="mx-0" @click="saveSeatingData" tile height="48" elevation="0">
                <v-icon> mdi-content-save </v-icon>
            </v-btn>
        </v-app-bar>
        <v-dialog v-model="seatingPreviewDialog" fullscreen>
            <SeatingPreview v-if="seatingPreviewDialog" :seatingData="seatingData" :backgroundImageUrl="backgroundImageUrl"
                :seatCategoriesMap="seatCategoriesMap" @closeDialog="seatingPreviewDialog = false" />
        </v-dialog>
        <v-container class="canvas pa-0 overflow-y-hidden" fluid>
            <v-row no-gutters class="h-100">
                <v-col ref="planCont" class="h-100" cols="8" xl="9">
                    <div id="canvas">

                    </div>
                    <v-tooltip :value="tooltip.show" :position-x="tooltip.x" :position-y="tooltip.y" absolute bottom
                        color="primary">
                        <div>{{ tooltip.numberOfSeats }}</div>
                    </v-tooltip>
                    <v-btn-toggle class="zoom-buttons">
                        <v-btn small @click="zoomOut" class="ma-0"><v-icon>mdi-magnify-minus-outline</v-icon></v-btn>
                        <v-btn small @click="resetZoom" class="ma-0"><v-icon>mdi-magnify-scan</v-icon></v-btn>
                        <v-btn small @click="zoomIn" class="ma-0"><v-icon>mdi-magnify-plus-outline</v-icon></v-btn>
                    </v-btn-toggle>
                </v-col>
                <v-col class="h-100 bg-white overflow-hidden overflow-y-auto" cols="4" xl="3">
                    <div v-if="!loading">
                        <PlanToolBox v-if="!showPricing && selectedTypes.length === 0 && !loading" :initial-plan-name="planName"
                            :initialBackground="backgroundImageUrl" :initialBackgroundOpacity="backgroundImage?.attrs.opacity" :toggleBackgroundProp="toggleBackground"
                            @enter-bg-overlay-mode="toggleBgOverlayMode" @update-plan-name="updatePlanName"
                            @update-background-image="updateBackgroundImage"
                            @update-background-opacity="updateBackgroundOpacity" />
                        <v-divider></v-divider>

                        <PricingToolBox v-if="showPricing" :hasSelection="selectedSeatsAttrs.length > 0"
                            :seats="seatsAttrs" :seatCategories="seatCategories"
                            @update-seat-category="updateSeatCategory" @toggleFocalPoint="toggleFocalPoint" />

                        <SeatToolBox
                            v-if="!showPricing && selectedTypes.includes('seat') &&  selectedSeatsAttrs?.length===1"
                            :seatCategories="seatCategories" :seats="selectedSeatsAttrs" @update-seat="updateSeat" />

                        <RowToolBox v-if="!showPricing && selectedTypes.includes('row')" :rows="selectedRowsAttrs"
                            :initialSeatNumbering="null" :initialRowNumbering="null"
                            @update-seat-numbering="updateSeatNumbering" @update-row-numbering="updateRowNumbering"
                            @update:rowSpacing="updateRowSpacing" @update-row-curve="updateRowCurve"
                            @update-row-alignment="updateRowAlignment" @update-row-skew="updateRowSkew"
                            @update-row:number="updateRowNumber" @update:seatSpacing="updateSeatSpacing"
                            @update-row:number-position="updateRowNumberPosition" />

                        <TableToolBox v-if="!showPricing && selectedTypes.includes('table')" 
                            :key="(selectedTablesAttrs && selectedTablesAttrs.length === 1)?selectedTablesAttrs[0].table_id:'TableToolBox'"
                            :tables="selectedTablesAttrs" @update-table:name="updateTableName"
                            @update-table:name-visibility="updateTableNameVisibility" @update-table="updateTable"
                            @update-table:seat-numbering="updateTableSeatNumbering"
                            @updateTableSettings="updateTableSettings" />

                        <AreaToolBox v-if="!showPricing && selectedAreasAttrs.length > 0" :areas="selectedAreasAttrs"
                            @update-area="updateArea" />
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
import randomString from "randomstring";
import * as d3 from "d3";
import Konva from "konva";
import {hexToHexa} from "./helpers";

import { faAlarmClock, faHouse } from "@fortawesome/pro-light-svg-icons";
import RowToolBox from "./RowToolBox";
import SeatToolBox from "./SeatToolBox";
import TableToolBox from "./TableToolBox.vue";
import rowmixin from "./rowmixin";
import { ROW_NUMBER_POSITIONS } from "./numbering";
import keyboardshortcuts from "./keyboardshortcuts";
import tablemixin from "./tablemixin";
import seatmixin from "./seatmixin";
import { ZOOM_LIMITS, ZOOM_STEP, COLORS, SEAT_RADIUS, SEAT_SPACE, ROW_SPACE, TABLE_WIDTH, TABLE_HEIGHT, TABLE_RADIUS, SEAT_GAP, GRID_SIZE, SNAP_THRESHOLD, SHOW_TEXT_THRESHOLD, SNAP_ANGLES, SNAP_ANGLES_THRESHOLD, CACHE_THRESHOLD, STAGE_EXTENT } from "./constants";
import { Seat } from "./Seat";
import editormixin from "./editormixin";
import SeatingPreview from "./SeatingPreview.vue";

import AreaToolBox from './AreaToolBox.vue';
import PlanToolBox from './PlanToolBox.vue';
import areamixin from './areamixin';
import { findIconDefinition } from "@fortawesome/fontawesome-svg-core";
import PricingToolBox from "./PricingToolBox.vue";
import TableRectIcon from "../../common/icons/TableRectIcon.vue";
import TableRoundIcon from "../../common/icons/TableRoundIcon.vue";
import { mapGetters } from "vuex";


export default {
    components: {
        RowToolBox,
        TableToolBox,
        SeatToolBox,
        SeatingPreview,
        AreaToolBox,
        PlanToolBox,
        TableRectIcon,
        TableRoundIcon,
        PricingToolBox
    },
    mixins: [editormixin, areamixin, rowmixin, tablemixin, seatmixin, keyboardshortcuts],
    data() {
        return {
            backgroundImage: null,
            backgroundImageUrl: null,
            backgroundOpacity: 1,
            backgroundImagePosition: null,
            backgroundImageScale: null,
            isBackgroundImageUpdated: false,
            deleteBackgroundImage: false,
            toggleBackground: false,
            showGrid: true,
            showPricing: false,
            showRowNumbers: true,
            showSeatNumber: true,
            isSelecting: false,
            isDrawing: false,
            isPanning: false,
            stage: null,
            layer: null,
            zoom: null,
            overlayLayer: null,
            bgLayer: null,
            transformerLayer: null,
            layerGroup: null,
            transformerGroup: null,
            shapeTransformer: null,
            transformerManager: null,
            bgTransformer: null,
            infiniteLine: null,
            background: null,
            debounceTimeout: null,
            focalPoint: null,
            rowNumberPositions: ROW_NUMBER_POSITIONS,
            width: 0, // example value, adjust as needed
            height: 0, // example value, adjust as needed
            margin: 50, 
            selected: [],
            selectedAreas: [],
            previewSeats: [],
            tooltip: {
                x: 0,
                y: 0,
                show: false,
                numberOfSeats: 0,
            }
        };
    },
    watch:{
        width(newWidth) {
            if (this.stage) {
                this.stage.width(newWidth);
            }
        },
        height(newHeight) {
            if (this.stage) {
                this.stage.height(newHeight);
            }
        },
    },
    computed: {
        ...mapGetters({
            imageUrl: "api/auth/imageUrl",
        }),
        seats() {
            return this.stage?.find('.seat').length + this.stage?.find('.table-seat').length
        },
        cursorMode() {
            if (this.editor.mode === 'pan') return 'grab';

            return this.editor.mode?.includes('draw') ? 'crosshair' : 'default';
        },
        selectionTarget() {
            if (this.editor.mode === 'select-seat') {
                return 'seat';
            }

            return this.editor.mode === 'select' ? 'row' : 'table';
        },
        selectedTypes() {
            return Array.from(new Set(this.selected.map(item => item.name().replace(' selected', ''))));
        },
        selectedAreasAttrs() {
            return this.selectedAreas.map(area => {
                const content = area.contentGroup;
                const icon = content?.findOne('.area-icon');
                const text = content?.findOne('.area-text');

                if (!content || !icon || !text) return {};

                return {
                    isRectangle: area.getClassName() === 'Rect',
                    areaText: text.text(),
                    textColor: text.fill(),
                    textVisible: text.visible(),
                    backgroundColor: area.fill(),
                    cornerRadius: area.getClassName() === 'Rect' ? area.cornerRadius() : 0,
                    strokeWidth: area.strokeWidth(),
                    strokeColor: area.stroke(),
                    iconVisible: icon.visible(),
                    iconColor: icon.fill(),
                    icon: icon.attrs.iconName,
                    iconScale: icon.scaleX(),
                }
            });
        },
        selectedItems() {
            return this.selected.map(item => ({ name: item.attrs.name, pos: item.getAbsolutePosition(), rotation: item.rotation() }))
        },
        selectedRows() {
            return this.selected.filter(item => item.hasName("row"));
        },
        selectedRowsAttrs() {
            return this.selectedRows.map(row => row.attrs);
        },
        selectedTables() {
            return this.selected.filter(item => item.hasName("table"));
        },
        selectedTablesAttrs() {
            return this.selectedTables.map(table => table.attrs);
        },
        seatsAttrs() {
            return this.stage?.find(".seat, .table-seat").map(seat => seat.attrs) || [];
        },
        selectedSeatsAttrs() {
            return this.selectedSeats.map(seat => seat.attrs);
        },
        selectedSeats() {
            return this.transformerGroup?.find(".seat, .table-seat") || [];
        },
    },
    methods: {
        getAssignedSeats(){
            this.deselectNodes();
            return this.layer.find(".seat, .table-seat").filter(seat=> !!seat.attrs.category)?.length || 0;
        },
        focalPointReach(){
            const rect = this.layer.getClientRect({relativeTo: this.stage}) || null;
            return rect ? (rect.width > rect.height? rect.width : rect.height) / 2 : 300;
        },
        addFocalPoint(focalPointPos = {x: this.stage.width() / 2, y: this.stage.height() / 2, }) {
            this.focalPoint = new Konva.Group({
                name: 'focal-point-group',
                draggable: true,
                visible: false,
            })

            const handle = new Konva.Circle({
                ...focalPointPos,
                radius: 25,
                strokeWidth:2,
                fill: '#0096ffdb',
            })

            this.focalPoint.add(handle)
            
            this.focalPoint.add(new Konva.Circle({
                ...focalPointPos,
                radius: 50,
                stroke: '#0096ff',
                fill: 'transparent',
                strokeWidth: 2,
                perfectDrawEnabled: false,
                hitStrokeWidth:0,
            }))
            this.focalPoint.handle = handle;
            this.focalPoint.on('dragend', this.updateSeatQualities);
            this.overlayLayer.add(this.focalPoint);
            this.focalPoint.moveToTop();
        },

        async updateSeatQualities() {
            const seats = this.layer.find('.seat, .table-seat');
            const focalPointPos = this.focalPoint.handle.getAbsolutePosition(this.stage);
            const focalPointReach = this.focalPointReach()
            seats.forEach(seat => {
                const seatPos = seat.getAbsolutePosition(this.stage)
                const distance = Math.sqrt(
                    (seatPos.x - focalPointPos.x) ** 2 +
                    (seatPos.y - focalPointPos.y) ** 2
                );

                const quality = Math.max(0, 1 - (distance / focalPointReach));
                seat.attrs.quality = quality;
            });

            this.layer.batchDraw();
            this.overlayLayer.batchDraw();
            await new Promise(requestAnimationFrame);
        },
        initializeFocalPoint() {
            this.addFocalPoint();
            this.updateSeatQualities();
        },
        toggleFocalPoint(enable){
            this.deselectNodes();
            Seat.showQuality = enable;
            this.focalPoint.visible(enable);
            this.layer.listening(!enable);
            this.updateSeatQualities();
        },
        updateDimensions() {
            if (this.$refs.planCont && this.$refs.planCont) {
                this.width = this.$refs.planCont.clientWidth;
                this.height = this.$refs.planCont.clientHeight;
            }
        },
        togglePricing() {
            this.showPricing = !this.showPricing
            if(!this.showPricing){
                this.toggleFocalPoint(false);
            }
            this.setMode("select")
        },
        updatePlanName(name) {
            this.planName = name;
            // You might want to update this in your data storage or API
        },
        updateBackgroundImage(imageUrl) {
            this.backgroundImageUrl = imageUrl;
            if(imageUrl){
                this.isBackgroundImageUpdated = true;
            }else {
                this.backgroundImage?.image(null);
                this.deleteBackgroundImage = true;
            }
            this.updateBackgroundLayer();
        },

        updateBackgroundOpacity(opacity) {
            this.backgroundImage?.opacity(opacity)
        },
        setBackgroundImage(attrs, imageUrl){
            if(attrs) {
                this.backgroundImage?.setAttrs(attrs);
            }

            if (imageUrl) {
                this.backgroundImageUrl = imageUrl;
                const image = new Image();
                image.src = this.backgroundImageUrl;
                image.onload = () => {
                    this.backgroundImage.setAttr("image", image)
                    this.backgroundImage.moveToTop();
                    this.bgLayer.batchDraw();
                };
            }
        },
        updateBackgroundLayer() {
            if (this.backgroundImageUrl) {
                const image = new Image();
                image.src = this.backgroundImageUrl;
                image.onload = () => {
                    this.backgroundImage.setAttr("image", image)
                    this.backgroundImage.moveToTop();
                    this.bgLayer.batchDraw();
                };
            }
        },
        calculateBounds() {
            const rect = this.layer.getClientRect({relativeTo: this.stage});
            return {
                x: rect.x - this.margin,
                y: rect.y - this.margin,
                width: rect.width + 2 * this.margin,
                height: rect.height + 2 * this.margin
            };
        },
        calculateMinZoom() {
            const bounds = this.calculateBounds();
            const scaleX = this.width / bounds.width;
            const scaleY = this.height / bounds.height;
            return Math.min(scaleX, scaleY);
        },
        zoomIn() {
            this.smoothZoom(this.editor.zoom * (2 + ZOOM_STEP));
        },

        zoomOut() {
            this.smoothZoom(this.editor.zoom - ZOOM_STEP);
        },
        resetZoom() {
            const bounds = this.calculateBounds();
            const minZoom = this.calculateMinZoom();

            // Calculate the center position
            const centerX = bounds.x + bounds.width / 2;
            const centerY = bounds.y + bounds.height / 2;

            // Create a new transform
            const transform = d3.zoomIdentity
                .translate(this.width / 2, this.height / 2)
                .scale(minZoom)
                .translate(-centerX, -centerY);

            // Apply the transform
            d3.select(this.stage.container())
                .transition()
                .duration(750) // Animation duration in milliseconds
                .call(this.zoom.transform, transform);
        },
        smoothZoom(targetScale) {
            const container = d3.select(this.stage.container());
            const currentTransform = d3.zoomTransform(container.node());

            const bounds = this.calculateBounds();
            const viewportWidth = this.stage.width();
            const viewportHeight = this.stage.height();

            // Clamp the target scale to the zoom limits
            targetScale = Math.max(this.zoom.scaleExtent()[0], Math.min(this.zoom.scaleExtent()[1], targetScale));

            // Calculate the center of the viewport in the current transformed coordinate system
            const viewportCenterX = (-currentTransform.x + viewportWidth / 2) / currentTransform.k;
            const viewportCenterY = (-currentTransform.y + viewportHeight / 2) / currentTransform.k;

            // Calculate the new transform
            let newTransform = d3.zoomIdentity
                .translate(viewportWidth / 2, viewportHeight / 2)
                .scale(targetScale)
                .translate(-viewportCenterX, -viewportCenterY);

            // Apply the new transform with a smooth transition
            container.transition()
                .duration(300)
                .call(this.zoom.transform, newTransform);
        },
        showRowNumber(rowGroup) {
            ROW_NUMBER_POSITIONS.forEach(position => {
                if (rowGroup.attrs.row_number_position.includes(position.value)) {
                    rowGroup[`rowNumber${position.value}`]?.show();
                } else {
                    rowGroup[`rowNumber${position.value}`]?.hide();
                }
            });
        },
        positionRowNumbers(rowGroup, transformerRotation = 0) {
            const seats = rowGroup.find('.seat');
            const rowNumberPositions = rowGroup.attrs.row_number_position || [];

            const positionRowNumber = (position, seatIndex1, seatIndex2) => {
                const seat1 = seats[seatIndex1];
                const seat2 = seats[seatIndex2];

                // Calculate the vector between the two seats
                let dx = seat2.x() - seat1.x();
                let dy = seat2.y() - seat1.y();

                // Normalize the vector
                const length = Math.sqrt(dx * dx + dy * dy);
                dx = dx / length;
                dy = dy / length;

                // Determine the offset direction (always pointing outwards)
                const offsetMultiplier = -1;

                // Set the offset distance
                const offset = 30; // Adjust this value to change how far the number is from the seat

                // Calculate the position for the row number
                const x = seat1.x() + offsetMultiplier * dx * offset;
                const y = seat1.y() + offsetMultiplier * dy * offset;

                // Get the appropriate row number text element
                const rowNumberText = rowGroup[`rowNumber${position}`];

                if (rowNumberText) {
                    rowNumberText.setAttrs({
                        x: x,
                        y: y,
                        rotation: -rowGroup.rotation() - transformerRotation, // Counteract the row's rotation
                        text: rowGroup.attrs.row_number,
                    });

                    // Center the text on its position
                    rowNumberText.offsetX(rowNumberText.width() / 2);
                    rowNumberText.offsetY(rowNumberText.height() / 2);
                }
            };

            if (rowNumberPositions.includes('Start') && seats.length >= 2) {
                positionRowNumber('Start', 0, 1);
            }

            if (rowNumberPositions.includes('End') && seats.length >= 2) {
                positionRowNumber('End', seats.length - 1, seats.length - 2);
            }
        },
        toggleGrid() {
            this.showGrid = !this.showGrid;

            if (this.background) {
                this.background.visible(this.showGrid);
                this.bgLayer.batchDraw();
            }
            // this.layer.toggleHitCanvas();
            //this.handleCache();
        },
        calculateNumberOfSeats(start, end) {
            const distance = Math.sqrt((end.x - start.x) ** 2 + (end.y - start.y) ** 2);
            const numberOfSeats = Math.floor(distance / SEAT_SPACE);
            return numberOfSeats;
        },
        snapToGrid(pos) {
            const snapToGridWithThreshold = (value) => {
                let gridSize = GRID_SIZE;
                let snapThreshold = SNAP_THRESHOLD;
                const remainder = value % gridSize;
                if (Math.abs(remainder) <= snapThreshold) {
                    return value - remainder;
                } else if (Math.abs(remainder) >= gridSize - snapThreshold) {
                    return value + (gridSize - remainder);
                }
                return parseInt(value);
            };

            return {
                x: snapToGridWithThreshold(pos.x),
                y: snapToGridWithThreshold(pos.y)
            };
        },
        getTextColor(backgroundColor) {
            // Fast conversion of hex to RGB
            const hexToRgb = (hex) => [
                parseInt(hex.slice(1, 3), 16),
                parseInt(hex.slice(3, 5), 16),
                parseInt(hex.slice(5, 7), 16)
            ];

            // Calculate relative luminance with precalculated constants
            const getLuminance = ([r, g, b]) => {
                const luminance = (c) => {
                    c /= 255;
                    return c <= 0.03928 ? c / 12.92 : Math.pow((c + 0.055) / 1.055, 2.4);
                };
                return 0.2126 * luminance(r) + 0.7152 * luminance(g) + 0.0722 * luminance(b);
            };

            // Precompute threshold
            const threshold = 0.179;

            // Process this.background color and compute luminance
            const rgb = hexToRgb(backgroundColor);
            const luminance = getLuminance(rgb);

            // Determine and return text color based on luminance
            return luminance > threshold ? '#000000' : '#FFFFFF';
        },
        toggleRowNumbers(enable) {
            this.showRowNumbers = enable;
            if (enable) {
                this.layer.find('.row').forEach(rowGroup => {
                    this.showRowNumber(rowGroup);
                    this.positionRowNumbers(rowGroup)
                });
            } else {
                this.layer.find('.row').forEach(rowGroup => {
                    rowGroup.rowNumberStart.hide();
                    // rowGroup.rowNumberMiddle.hide();
                    rowGroup.rowNumberEnd.hide();
                });
            }
        },
        getGridDims(x1, y1, x2, y2) {
            const distance = Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);
            if (distance < SEAT_SPACE) {
                return;
            }

            const minX = Math.min(x1, x2);
            const maxX = Math.max(x1, x2);
            const minY = Math.min(y1, y2);
            const maxY = Math.max(y1, y2);

            let gridWidth = Math.ceil((maxX - minX) / SEAT_SPACE);
            let gridHeight = Math.ceil((maxY - minY) / ROW_SPACE);

            return { width: gridWidth, height: gridHeight, x: minX, y: minY };
        },
        async updateSelection(callBack, shouldUpdateTransformer = false) {
            // this.transformerGroup.clearCache();

            callBack();

            if (shouldUpdateTransformer) {
                this.transformerManager.forceUpdate();
            }
            this.transformerGroup.cache();
            this.transformerLayer.batchDraw();
            await new Promise(requestAnimationFrame);
        },
        drawInfiniteLine(start, end) {
            // Clear previous preview
            this.clearRowLinePreview();

            // Calculate the number of seats
            const numberOfSeats = this.calculateNumberOfSeats(start, end);

            // Calculate the vector from start to end
            const dx = end.x - start.x;
            const dy = end.y - start.y;
            const length = Math.sqrt(dx * dx + dy * dy);

            // If the line is too short, don't draw anything
            if (length < SEAT_SPACE) {
                return;
            }

            // Normalize the vector
            const unitX = dx / length;
            const unitY = dy / length;

            // Create preview seats
            this.previewSeats = [];
            for (let i = 0; i < numberOfSeats; i++) {
                const seatX = start.x + i * SEAT_SPACE * unitX;
                const seatY = start.y + i * SEAT_SPACE * unitY;

                const previewSeat = new Konva.Circle({
                    x: seatX,
                    y: seatY,
                    radius: SEAT_RADIUS,
                    stroke: COLORS[2][0],
                    strokeWidth: 2,
                    name: 'preview-seat'
                });

                this.previewSeats.push(previewSeat);
                this.transformerLayer.add(previewSeat);
            }

            // Update the line
            this.infiniteLine.points([start.x, start.y, end.x, end.y]);
            this.infiniteLine.visible(true);

            // Update the number of seats tooltip
            this.updateNumberOfSeatsTooltip(numberOfSeats);

            // Redraw the layer
            this.transformerLayer.batchDraw();
        },
        clearRowLinePreview() {
            if (this.previewSeats) {
                this.previewSeats.forEach(seat => seat.destroy());
                this.previewSeats = [];
            }
            this.infiniteLine.visible(false);
            this.tooltip.show = false;
        },
        async renderRowsAndSeats(rows) {
            console.time("renderRowsAndSeats");

            this.stage.container().style.cursor = 'wait';
            this.deselectNodes();
            for (const row of rows) {
                await new Promise(resolve => {
                    this.layer.add(this.addRowGroupe(row));
                    resolve();
                });
            }

            console.timeEnd("renderRowsAndSeats");
            this.stage.container().style.cursor = this.cursorMode;
        },
        updateRowBackBone(rowGroup, toggle) {
            rowGroup.rowBackBone.points(rowGroup.find('.seat')/*.sort((a, b) => a.attrs.seatIndex - b.attrs.seatIndex)*/.map(seat => [seat.x(), seat.y()]).flat());
            rowGroup.rowBackBone.visible(toggle);
        },
        addRowGroupe(row) {
            const rowGroup = new Konva.Group({
                name: "row",
                ...row,
            });

            rowGroup.id(row.row_id);
            // Add row numbers at the start and end
            const rowNumberTextStart = new Konva.Text({
                name: "row-number-start",
                text: row.row_number,
                fontSize: 16,
                fontFamily: 'Calibri',
                align: 'right',
                fill: 'black',
                listening: false
            });

            // @TODO: Add row number in the middle
            // const rowNumberTextMiddle = new Konva.Text({
            //     name: "row-number-middle",
            //     text: row.row_number,
            //     fontSize: 16,
            //     fontFamily: 'Calibri',
            //     align: 'right',
            //     fill: 'black',
            //     listening: false
            // });

            const rowNumberTextEnd = new Konva.Text({
                name: "row-number-end",
                text: row.row_number,
                fontSize: 16,
                fontFamily: 'Calibri',
                fill: 'black',
                drawBorder: true,
                listening: false
            });

            const rowBackBone = new Konva.Line({
                name: "rowBackBone",
                stroke: hexToHexa(COLORS[2][0], 0.6),
                strokeWidth: 2,
                lineCap: 'round',
                lineJoin: 'round',
                tension: 0.5,
                listening: false,
                perfectDrawEnabled: false,
                shadowForStrokeEnabled: false,
                visible: false,
                hitStrokeWidth: 0,
            })
            // Add seats
            rowGroup.add(rowBackBone);
            let seatIndex = 0;
            for (const seat of row.seats) {
                const seatShape = this.addSeatGroup(seat);
                rowGroup.add(seatShape);

                if (seatIndex === 0) {
                    rowGroup.firstSeat = seatShape
                }

                seatIndex++;

                if (seatIndex === row.seats.length - 1) {
                    rowGroup.lastSeat = seatShape
                }
            }

            rowGroup.add(rowNumberTextStart);
            rowGroup.add(rowNumberTextEnd);

            rowGroup.rowNumberStart = rowNumberTextStart;
            rowGroup.rowNumberEnd = rowNumberTextEnd;
            rowGroup.rowBackBone = rowBackBone;
            this.updateRowBackBone(rowGroup, false);
            this.positionRowNumbers(rowGroup);
            //rowGroup.cache({drawBorder: true});
            return rowGroup;
        },
        saveSeatingMapImage() {
            var link = document.createElement('a');
            link.download = `Seating_Map_${this.planName?.trim()}`;
            link.href = this.stage.toDataURL({ pixelRatio: 4 });
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        // making seat a customer shape 
        addSeatGroup(seat, name = "seat") {
            return new Seat({
                ...seat,
                name,
                radius: SEAT_RADIUS,
                fill: this.getCategoryColor(seat.category),
                categoryColor: this.getCategoryColor(seat.category),
                stroke: seat.category ? "transparent" : hexToHexa(COLORS[7][1], 0.8),
                perfectDrawEnabled: false,
                shadowForStrokeEnabled: false,
                hitStrokeWidth: 0,
                listening: name === "table-seat" ? true : false,
                // transformsEnabled: "position",
            })
        },
        setMode(mode) {
            if (!this.modes.hasOwnProperty(mode)) {
                console.error(`Mode ${mode} not found`);
                return;
            }

            this.modes[mode].handler();
            this.editor.mode = mode;
        },
        selectSpecificNodes(nodes) {
            if(this.toggleBackground)
                return;

            console.time("selectSpecificNodes");
            this.deselectNodes();

            const rowIDs = new Set();
            this.selected = [];

            for (const node of nodes) {
                const nodeName = node.name();

                if (this.editor.mode === "select") {
                    if (nodeName === "table") {
                        this.selected.push(node);
                        this.transformerGroup.add(node);
                        node.draggable(false);
                    } else if (nodeName === "seat") {
                        const parent = node.getParent();
                        const parentId = parent.id();
                        if (!rowIDs.has(parentId)) {
                            rowIDs.add(parentId);
                            this.selected.push(parent);
                            this.transformerGroup.add(parent);
                            this.positionRowNumbers(parent);
                            this.updateRowBackBone(parent, true);
                        }
                    }
                } else if (this.editor.mode === "select-seat") {
                    if (nodeName === "seat") {
                        const absPos = node.getAbsolutePosition();
                        this.selected.push(node);
                        this.transformerGroup.add(node);
                        node.absolutePosition(absPos);
                    }
                }
            }

            if (this.selected.length) {
                if (this.editor.mode === "select-seat") {
                    this.transformerManager.rotateEnabled(this.selected.length > 1);
                } else {
                    this.transformerManager.rotateEnabled(true);
                }

                this.transformerManager.nodes([this.transformerGroup]);
                this.transformerManager.forceUpdate();
                this.transformerLayer.batchDraw();
                this.transformerGroup.cache();
            }

            console.timeEnd("selectSpecificNodes");
        },
        selectNodes(box) {

            if(this.toggleBackground)
                return;

            console.time("selectNodes");
            if (box === "all") {
                this.setMode("select");
                const nodes = this.layer.find('.row, .table');
                for (const node of nodes) {
                    node.addName("selected")
                    if (node.hasName("row")) {
                        this.updateRowBackBone(node, true);
                        this.positionRowNumbers(node)
                    } else {
                        node.draggable(false);
                    }
                }
                this.selected.push(...nodes);
                this.transformerGroup.add(...nodes);
            } else {
                const nodes = this.layer.find('.seat');
                if (this.editor.mode === "select") {
                    nodes.push(...this.layer.find('.table'));
                }

                // remove all children for better performance
                //this.layer.removeChildren();

                // then check intersections and add all shape into correct container
                const rowIDs = [];
                this.selected = [];
                for (const node of nodes) {
                    var intersected = Konva.Util.haveIntersection(
                        box,
                        node.getClientRect()
                    );

                    if (intersected) {
                        if (this.editor.mode === "select") {
                            if (node.hasName("table")) {
                                node.addName("selected")
                                this.selected.push(node);
                                this.transformerGroup.add(node);
                            } else {
                                const parent = node.getParent()
                                if (!rowIDs.includes(parent.id())) {
                                    rowIDs.push(parent.id());
                                    parent.addName("selected")
                                    this.selected.push(parent)
                                    this.transformerGroup.add(parent);
                                    this.updateRowBackBone(parent, true);
                                }
                            }
                        } else if (this.editor.mode === "select-seat") {
                            node.addName("selected")
                            const absPos = node.getAbsolutePosition();
                            this.selected.push(node);
                            this.transformerGroup.add(node);
                            node.absolutePosition(absPos);
                        }
                    }
                }
            }

            if (this.selected.length) {

                if (this.editor.mode === "select-seat") {
                    this.transformerManager.rotateEnabled(this.selected.length > 1);
                } else {
                    this.transformerManager.rotateEnabled(true);
                }

                this.transformerManager.nodes([this.transformerGroup]);
                this.transformerManager.forceUpdate();
                this.transformerLayer.batchDraw();
                this.transformerGroup.cache();
                //this.handleCache();
            }
            console.timeEnd("selectNodes");
        },
        deselectNodes() {

            if (this.selectedAreas.length) {
                this.shapeTransformer.nodes([]);
                this.selectedAreas = [];
            }

            if (this.selected.length < 1) {
                return;
            }

            console.time("deselectNodes");
            const rowIDs = {};
            if (this.transformerGroup.children.length) {
                for (const node of this.selected) {
                    let transform = node.getAbsoluteTransform(this.stage);
                    let attrs = transform.decompose();

                    if (this.selectedTypes.includes("row") || this.selectedTypes.includes("table")) {
                        node.removeName("selected")
                        node.moveTo(this.layer);
                        node.setAttrs({
                            x: attrs.x,
                            y: attrs.y,
                            rotation: attrs.rotation,
                        });

                        if (node.hasName("row")) {
                            node.rowBackBone.visible(false);
                        } else {
                            node.draggable(true);
                            this.updateTableNamePosition(node);
                        }

                    } else if (this.selectedTypes.includes("seat")) {
                        node.removeName("selected")
                        if (!rowIDs.hasOwnProperty(node.attrs.row_id)) {
                            rowIDs[node.attrs.row_id] = this.layer.findOne(`#${node.attrs.row_id}`);
                            rowIDs[node.attrs.row_id].rowBackBone?.visible(false);
                        }
                        // transform = node.getAbsoluteTransform(rowIDs[node.attrs.parentId]);
                        // attrs = transform.decompose();
                        const absPos = node.getAbsolutePosition();
                        node.moveTo(rowIDs[node.attrs.row_id]);
                        node.setAttrs({
                            x: attrs.x,
                            y: attrs.y,
                            //rotation: attrs.rotation,
                        })
                        node.absolutePosition(absPos);
                    }
                }

                if (this.selectedTypes.includes("seat")) {
                    for (const rowID in rowIDs) {
                        const seats = _.orderBy(rowIDs[rowID].find('.seat'), 'attrs.seatIndex', 'asc');
                        seats.forEach(seat => {
                            seat.remove();
                        });
                        rowIDs[rowID].add(...seats);
                        rowIDs[rowID].firstSeat = seats[0]
                        rowIDs[rowID].lastSeat = seats[seats.length - 1]
                    }
                }

                this.selected = [];
                this.transformerManager.nodes([])
                // reset group transforms
                this.transformerGroup.destroyChildren();
                this.transformerGroup.setAttrs({
                    x: 0,
                    y: 0,
                    scaleX: 1,
                    scaleY: 1,
                    rotation: 0,
                });
                this.transformerGroup.clearCache();
                this.transformerManager.rotateEnabled(true);
            }
            console.timeEnd("deselectNodes");
            //this.handleCache();
        },
        selectAll() {
            this.selectNodes("all")
        },
        removeAllSelected() {
            if (this.shapeTransformer.nodes().length) {
                this.shapeTransformer.nodes().forEach(node => {
                    node.contentGroup.destroy();
                    node.destroy();
                });
                this.shapeTransformer.nodes([]);
                this.selectedAreas = [];
            }

            if (this.transformerManager.nodes().length < 1) return;
            if (this.selectedTypes.includes("table")) {
                this.selectedTables.forEach(table => {
                    table.tableNameText.destroy();
                    table.destroy();
                });

                this.transformerGroup.destroyChildren();
                this.transformerManager.nodes([]);
                this.transformerGroup.clearCache();
                this.selected = [];
            } else {
                this.transformerGroup.destroyChildren();
                this.transformerManager.nodes([]);
                this.transformerGroup.clearCache();
                this.selected = [];
                this.cleanUpRows();
            }
        },
        cleanUpRows() {
            this.layer.find(".row").forEach(row => {
                const seats = row.find(".seat");
                if (seats.length < 1) {
                    row.destroy();
                    return;
                }

                row.firstSeat = seats[0]
                row.lastSeat = seats[seats.length - 1]
            })
        },
        cancelAllActions() {
            this.isSelecting = false;
            this.isDrawing = false;

            this.clearRowLinePreview();
            this.infiniteLine.visible(false);

            this.transformerLayer.find('.gridSizePreviewGroup').forEach((group) => {
                group.visible(false);
            });

            this.transformerLayer.find('.selectionRectangle').forEach((rect) => {
                rect.visible(false);
            });

            this.transformerLayer.draw();
        },
        focusOnBox() {
            this.saveSeatingMapImage();
        },
        adjustPosition(newPos, newScale) {
            const stageWidth = this.stage.width();
            const stageHeight = this.stage.height();

            const scaledMinX = STAGE_EXTENT[0] * newScale;
            const scaledMaxX = STAGE_EXTENT[2] * newScale;
            const scaledMinY = STAGE_EXTENT[1] * newScale;
            const scaledMaxY = STAGE_EXTENT[3] * newScale;

            newPos.x = Math.max(stageWidth - scaledMaxX, Math.min(stageWidth - scaledMinX, newPos.x));
            newPos.y = Math.max(stageHeight - scaledMaxY, Math.min(stageHeight - scaledMinY, newPos.y));

            return newPos;
        },
        updateNumberOfSeatsTooltip(seats) {
            const { x, y } = this.stage.getPointerPosition();
            this.tooltip.numberOfSeats = seats;
            this.tooltip.x = x;
            this.tooltip.y = y;
            this.tooltip.show = true;
        },
        handleZoom(event) {
            const { transform } = event;

            // Update stage scale and position
            this.stage.scale({ x: transform.k, y: transform.k });
            this.stage.position({
                x: transform.x,
                y: transform.y
            });

            // Update editor zoom state
            this.editor.zoom = transform.k;

            // Update background
            this.updateBackground();
        },
        updateBackground() {
            const transform = d3.zoomTransform(this.stage.container());

            // Ensure background rect is in the top-left of the canvas
            this.background.absolutePosition({ x: 0, y: 0 });

            // Set the dimensions of the background rect to match the canvas
            this.background.size({
                width: this.stage.width() / transform.k,
                height: this.stage.height() / transform.k
            });

            // Apply movement to the fill pattern
            this.background.fillPatternOffset({
                x: -transform.x / transform.k,
                y: -transform.y / transform.k
            });
        },
        updateTableNamePosition(tableGroup) {
            const text = tableGroup.tableNameText
            if (text) {
                const tablePos = tableGroup.getClientRect();
                const tableDims = tableGroup.getClientRect();
                // Calculate the center point of the rotated shape
                const centerX = tablePos.x + tableDims.width / 2;
                const centerY = tablePos.y + tableDims.height / 2;

                text.absolutePosition({
                    x: centerX,
                    y: centerY
                });
            }
        },
        selectArea(shape) {
            this.deselectNodes();

            this.shapeTransformer.nodes([shape]);
            this.transformerLayer.batchDraw();
            this.selectedAreas = [shape];
        },
        initEditor() {
            const self = this;
            let selectionStartX = 0;
            let selectionStartY = 0;

            const drawSelectionRect = (x1, y1, x2, y2) => {
                const minX = Math.min(x1, x2);
                const maxX = Math.max(x1, x2);
                const minY = Math.min(y1, y2);
                const maxY = Math.max(y1, y2);
                const width = maxX - minX;
                const height = maxY - minY;

                selectionRectangle.setAttrs({
                    x: minX,
                    y: minY,
                    width,
                    height
                });
            }

            const drawGridSizePreviewRect = (x1, y1, x2, y2) => {
                const minX = Math.min(x1, x2);
                const maxX = Math.max(x1, x2);
                const minY = Math.min(y1, y2);
                const maxY = Math.max(y1, y2);
                const width = maxX - minX;
                const height = maxY - minY;

                let gridWidth = Math.floor((maxX - minX) / SEAT_SPACE) + 1;
                let gridHeight = Math.floor((maxY - minY) / ROW_SPACE) + 1;

                const centerX = width / 2;
                const centerY = height / 2;
                const gridSizeText = `${gridHeight}x${gridWidth}`;

                gridSizePreviewGroup.setAttrs({
                    x: minX,
                    y: minY,
                });

                gridSizePreviewRect.setAttrs({
                    x: 0,
                    y: 0,
                    width,
                    height
                });

                gridSizePreviewText.setAttrs({
                    x: centerX,
                    y: centerY,
                    text: gridSizeText,
                    scaleX: 1 / this.stage.scaleX(),
                    scaleY: 1 / this.stage.scaleY(),
                })

                gridSizePreviewText.offsetX(gridSizePreviewText.width() / 2);
                gridSizePreviewText.offsetY(gridSizePreviewText.height() / 2);

                this.updateNumberOfSeatsTooltip(gridWidth * gridHeight)
            }


            this.stage = new Konva.Stage({
                container: 'canvas',
                width: this.width,
                height: this.height,
                renderer: 'webgl'
            });

            const normalZoomFactor = 0.05; // Adjust this for normal zoom speed
            const fastZoomFactor = 0.2;   // Adjust this for Ctrl+zoom speed

            // Set up D3 zoom
            this.zoom = d3.zoom()
                // .translateExtent(STAGE_EXTENT) figure out a better limit 
                // .extent(STAGE_EXTENT)
                .scaleExtent(ZOOM_LIMITS)
                .on('zoom', this.handleZoom)
                .on('start', (event) => {
                    this.isPanning = true;
                })
                .on('end', (event) => {
                    this.isPanning = false;
                })
                .filter((event) => {
                    event.preventDefault();
                    // Allow zooming and panning only if the Ctrl key is pressed or editor mode is pan
                    return event.ctrlKey || this.editor.mode === 'pan';
                }).wheelDelta((event) => {
                    // Determine zoom factor based on whether Ctrl is pressed
                    const zoomFactor = fastZoomFactor;

                    // Calculate delta. The negation is because wheel up should zoom in
                    return -event.deltaY * (event.deltaMode === 1 ? 0.05 : event.deltaMode ? 1 : 0.002) * zoomFactor;
                });

            d3.select(this.stage.container()).call(this.zoom);

            this.layer = new Konva.Layer({ name: "layer", listening: true });
            
            this.transformerLayer = new Konva.Layer({ name: "transformerLayer", listening: true });
            this.bgLayer = new Konva.Layer({ name: "bgLayer", listening: true });
            this.overlayLayer = new Konva.Layer({ name: "overlayLayer", listening: true});


            this.infiniteLine = new Konva.Line({
                points: [],
                stroke: 'blue',
                strokeWidth: 1,
                dash: [10, 5],
                name: 'infinite-line',
                listening: false,
                visible: false,
            });

            this.transformerLayer.add(this.infiniteLine)

            this.transformerManager = new Konva.Transformer({
                rotationSnapTolerance: 15,
                resizeEnabled: false,
                rotateEnabled: true,
                useSingleNodeRotation: true,
                shouldOverdrawWholeArea: true,
                borderDash: [3, 3],
                anchorStyleFunc: (anchor) => {
                    if (anchor.hasName('rotater')) {
                        anchor.cornerRadius(10);
                    }
                },
            });
            this.transformerLayer.add(this.transformerManager);

            this.shapeTransformer = new Konva.Transformer({
                useSingleNodeRotation: true,
                shouldOverdrawWholeArea: true,
                ignoreStroke: true,
                borderDash: [3, 3],
                anchorStyleFunc: (anchor) => {
                    if (anchor.hasName('rotater')) {
                        anchor.cornerRadius(10);
                    }
                },
            });


            this.shapeTransformer.on('transformstart', (e) => {
                const node = e.target;
                const content = node.contentGroup;
                if (node && content) {
                    this.updateAreaContentPosition(node, content);
                    content.visible(false);
                }
            })

            this.shapeTransformer.on('transformend', (e) => {
                const node = e.target;
                const content = node.contentGroup;
                if (node && content) {
                    this.updateAreaContentPosition(node, content);
                    content.visible(true);
                }
            })

            this.transformerManager.on('transformstart', () => {
                if (this.editor.mode !== 'select') {
                    return;
                }

                this.transformerGroup.children.forEach(node => {
                    if (node.name() !== 'table') {
                        return;
                    }

                    const tableNameText = node.tableNameText;
                    if (tableNameText) {
                        tableNameText.visible(false);
                    }
                })
            })


            this.transformerLayer.add(this.shapeTransformer);


            this.bgTransformer = new Konva.Transformer({
                keepRatio: true,
                enabledAnchors: ['top-left', 'top-right', 'bottom-left', 'bottom-right'],
                borderDash: [3, 3],
                anchorStyleFunc: (anchor) => {
                    if (anchor.hasName('rotater')) {
                        anchor.cornerRadius(10);
                    }
                },
            });
            
            this.bgLayer.add(this.bgTransformer);

            this.backgroundImage = new Konva.Image({
                image: null,
                x: 0,
                y: 0,
                width: this.stage.width(),
                height: this.stage.height(),
                fill: "transparent",
                stroke: "transparent",
                listening: false,
                strokeWidth: 0,
            });
            
            this.bgLayer.add(this.backgroundImage);
            
            this.backgroundImage.moveToTop();


            this.bgTransformer.on('transformend', this.updateBackgroundTransform);

            this.transformerGroup = new Konva.Group({
                name: "transformerGroup",
                draggable: false,
                listening: false,
            });
            this.transformerLayer.add(this.transformerGroup);

            // set canvas this.background pattern
            this.background = new Konva.Rect({
                name: "grid-background",
                x: this.stage.x(),
                y: 0,
                width: parseInt(this.stage.width()),
                height: parseInt(this.stage.height()),
                listening: false,
            });
            const image = new window.Image();
            image.src = require('@/assets/images/line-grid.svg');

            image.onload = () => {
                this.background.fillPatternImage(image)
            }


            this.bgLayer.add(this.background);

            // add a new feature, lets add ability to draw selection rectangle
            const selectionRectangle = new Konva.Rect({
                name: 'selectionRectangle',
                fill: hexToHexa(COLORS[2][0], 0.3),
                visible: false,
                // disable events to not interrupt with events
                listening: false,
            });

            const gridSizePreviewRect = new Konva.Rect({
                fill: hexToHexa(COLORS[0][0], 0.6),
                visible: true,
                // disable events to not interrupt with events
                listening: false,
            });

            const gridSizePreviewText = new Konva.Text({
                name: "seat-number",
                x: 0,
                y: 0,
                text: "",
                fontSize: 20,
                centeredScaling: true,
                fontFamily: 'Calibri',
                fill: 'white',
                listening: false
            });


            const gridSizePreviewGroup = new Konva.Group({
                name: "gridSizePreviewGroup",
                visible: false,
            });

            gridSizePreviewGroup.add(gridSizePreviewRect);
            gridSizePreviewGroup.add(gridSizePreviewText);

            this.transformerLayer.add(gridSizePreviewGroup);

            this.transformerLayer.add(selectionRectangle);

            this.layerGroup = new Konva.Group({
                name: "layerGroup",
            });
            this.layer.add(this.layerGroup);



            // add the this.layer to the this.stage
            this.stage.add(this.bgLayer);
            this.stage.add(this.layer);
            this.stage.add(this.transformerLayer);
            this.stage.add(this.overlayLayer);


            this.stage.on('dragstart', (e) => {
                if (e.evt.ctrlKey)
                    return
                const target = e.target;
                const tableGroup = target.findAncestor('.table', true);
                if (tableGroup) {
                    tableGroup.tableNameText.visible(false);
                    tableGroup.moveTo(this.transformerLayer);
                }

                if (target.name() === "area-shape") {
                    target.contentGroup.visible(false);
                    target.moveTo(this.transformerLayer);
                }

            });

            this.stage.on('dragend', (e) => {
                if (e.evt.ctrlKey)
                    return
                const target = e.target;
                const tableGroup = target.findAncestor('.table', true);
                if (tableGroup) {
                    tableGroup.moveTo(this.layer);
                    tableGroup.tableNameText.visible(tableGroup.attrs.tableNameVisible);
                    this.updateTableNamePosition(tableGroup);
                }

                if (target.name() === "area-shape") {
                    target.moveTo(this.layer);
                    this.updateAreaContentPosition(target, target.contentGroup);
                    target.contentGroup.visible(true);
                }
            });

            this.stage.on('click tap', (e) => {
                if (e.evt.ctrlKey)
                    return


                const target = e.target;
                const tableGroup = target.findAncestor('.table');

                if (tableGroup) {
                    this.selectSpecificNodes([tableGroup]);
                }

                if (target.name() === "area-shape") {
                    if (this.editor.mode === 'select') {
                        this.selectArea(target);
                    }
                }

            });


            // DRAWING EVENTS
            this.stage.on('mousedown touchstart', (e) => {
                selectionStartX = this.stage.getRelativePointerPosition().x;
                selectionStartY = this.stage.getRelativePointerPosition().y;

                if (e.evt.ctrlKey || this.editor.mode === 'pan') {
                    return;
                }

                if (this.editor.mode === 'draw-rows' && e.target.getClassName() === 'Stage') {
                    this.isDrawing = true;
                    gridSizePreviewGroup.visible(true);
                    drawGridSizePreviewRect(selectionStartX, selectionStartY, selectionStartX, selectionStartY);

                    return;
                }

                if (this.editor.mode === 'draw-row' && e.target.getClassName() === 'Stage') {
                    this.isDrawing = true;
                    this.drawInfiniteLine({ x: selectionStartX, y: selectionStartY }, { x: selectionStartX, y: selectionStartY });
                    return;
                }

                if (this.editor.mode.includes('select') && e.target.getClassName() === 'Stage' && !Seat.showQuality) {
                    this.isSelecting = true;

                    drawSelectionRect(selectionStartX, selectionStartY, selectionStartX, selectionStartY);
                    selectionRectangle.visible(true);
                    // move old selection back to original this.layer
                    this.deselectNodes();
                }
            });

            this.stage.on('mousemove touchmove', () => {
                if (this.isPanning) return;
                if (this.editor.mode === 'draw-rows' && this.isDrawing) {
                    const x = this.stage.getRelativePointerPosition().x;
                    const y = this.stage.getRelativePointerPosition().y;
                    drawGridSizePreviewRect(selectionStartX, selectionStartY, x, y);
                    return;
                }


                if (this.editor.mode === 'draw-row' && this.isDrawing) {
                    const x = this.stage.getRelativePointerPosition().x;
                    const y = this.stage.getRelativePointerPosition().y;

                    this.drawInfiniteLine({ x: selectionStartX, y: selectionStartY }, { x, y });
                    return;
                }

                if (this.isSelecting && this.editor.mode.includes('select')) {
                    const x = this.stage.getRelativePointerPosition().x;
                    const y = this.stage.getRelativePointerPosition().y;
                    drawSelectionRect(selectionStartX, selectionStartY, x, y);
                    return;
                }
            });

            this.stage.on('mouseup touchend', async () => {

                if (this.editor.mode === 'draw-rows' && this.isDrawing) {
                    gridSizePreviewGroup.visible(false);
                    const gridDims = this.getGridDims(selectionStartX, selectionStartY, this.stage.getRelativePointerPosition().x, this.stage.getRelativePointerPosition().y);
                    if (gridDims) {
                        const rows = this.generateRowsAndSeats(gridDims.x, gridDims.y, gridDims.width, gridDims.height);
                        await this.renderRowsAndSeats(rows);
                    }
                }

                if (this.editor.mode === 'draw-row' && this.isDrawing) {
                    this.infiniteLine.visible(false);
                    const rows = this.generateRowOfSeatsFromLine({ x: selectionStartX, y: selectionStartY }, this.stage.getRelativePointerPosition());
                    await this.renderRowsAndSeats([rows]);
                    this.clearRowLinePreview();
                }

                if (this.isSelecting && this.editor.mode.includes('select')) {
                    // update visibility in timeout, so we can check it in click event
                    setTimeout(() => {
                        selectionRectangle.visible(false);
                    });

                    this.selectNodes(selectionRectangle.getClientRect())
                }

                this.isDrawing = false;
                this.isSelecting = false;
                this.tooltip.show = false;
            });

            // clicks should select/deselect shapes
            this.stage.on('click tap', (e) => {
                if (e.target.getClassName() === 'Stage') {
                    if (this.toggleBackground) {
                        this.toggleBgOverlayMode(false);
                    }

                    if (this.editor.mode === 'add-area-rect') {
                        this.renderArea(this.generateArea("rect"))
                    } else if (this.editor.mode === 'add-area-circle') {
                        this.renderArea(this.generateArea("circle"))
                    } else {
                        this.deselectNodes();

                    }
                }
            });

            this.stage.on('mouseover', (e) => {

                if (e.target?.name().includes('table') || e.target?.name() === "back") {
                    this.stage.container().style.cursor = 'move';
                } else {
                    this.stage.container().style.cursor = self.cursorMode;
                }
            })
        },
        clearCached() {
            this.layer.find('.table,.row').forEach(node => {
                node.clearCache();
            });
        },
        handleCache() {
            console.time("handleCache");
            const scale = this.stage.scaleX();
            const stagePos = this.stage.position();
            const vpWidth = this.stage.width();
            const vpHeight = this.stage.height();

            // Cache DOM queries
            const nodes = this.layer.find('.row, .table');

            const chunkSize = 1000;
            let processedNodes = 0;

            const processChunk = () => {
                const endIndex = Math.min(processedNodes + chunkSize, nodes.length);

                for (let i = processedNodes; i < endIndex; i++) {
                    const node = nodes[i];


                    const isVisible = node.isClientRectOnScreen()
                    node.visible(isVisible);

                    if (isVisible) {
                        if (scale < CACHE_THRESHOLD && !node.isCached()) {
                            node.cache({ drawBorder: true });
                        } else if (scale >= CACHE_THRESHOLD && node.isCached()) {
                            node.clearCache();
                        }
                    } else if (!node.isCached()) {
                        node.cache({ drawBorder: true });
                    }
                }

                processedNodes = endIndex;

                if (processedNodes < nodes.length) {
                    // Schedule next chunk
                    requestAnimationFrame(processChunk);
                } else {
                    // All chunks processed, perform final draw
                    this.layer.batchDraw();
                    console.timeEnd("handleCache");
                }
            };

            // Start processing chunks
            requestAnimationFrame(processChunk);
        }
    },
    mounted() {
        
        this.$nextTick(() => {
            this.updateDimensions();
            this.initEditor();
            // Add this new event listener
            this.transformerManager.on('dragmove', (e) => {
                if (e.evt.shiftKey) {
                    const stageScale = this.stage.scaleX();
                    const stagePos = this.stage.position();

                    // Get the current position of the this.transformerGroup
                    const absPos = this.transformerGroup.absolutePosition();

                    // Convert to relative position (considering this.stage drag and zoom)
                    const relPos = {
                        x: (absPos.x - stagePos.x) / stageScale,
                        y: (absPos.y - stagePos.y) / stageScale
                    };

                    // Snap to grid
                    const snappedRelPos = this.snapToGrid(relPos);

                    // Convert back to absolute position
                    const snappedAbsPos = {
                        x: snappedRelPos.x * stageScale + stagePos.x,
                        y: snappedRelPos.y * stageScale + stagePos.y
                    };

                    // Apply the snapped position
                    this.transformerGroup.absolutePosition(snappedAbsPos);

                    // Update the transformer
                    this.transformerManager.update();
                }
            });

            this.transformerManager.on('dragstart', () => {
                if (this.editor.mode !== 'select') {
                    return;
                }

                this.transformerGroup.children.forEach(node => {
                    if (node.name() !== 'table') {
                        return;
                    }
                    const tableNameText = node.tableNameText;
                    if (tableNameText) {
                        tableNameText.visible(false);
                        this.updateTableNamePosition(node);
                    }
                })
            })

            this.transformerManager.on('dragend', () => {
                if (this.editor.mode !== 'select') {
                    return;
                }
                this.transformerGroup.clearCache();
                this.transformerGroup.children.forEach(node => {
                    if (node.name() !== 'table') {
                        return;
                    }

                    const tableNameText = node.tableNameText;
                    if (tableNameText) {
                        tableNameText.visible(node.attrs.tableNameVisible);
                        this.updateTableNamePosition(node);
                    }
                })
                this.transformerGroup.cache();
            })

            this.transformerManager.on("transform", (e) => {
                const rotationSnaps = this.transformerManager.rotationSnaps();
                if (e.evt.shiftKey) {
                    if (rotationSnaps.length < 1) {
                        this.transformerManager.rotationSnaps(SNAP_ANGLES);
                        this.transformerManager.rotationSnapTolerance(SNAP_ANGLES_THRESHOLD);
                    }
                } else {
                    if (rotationSnaps.length > 0) {
                        this.transformerManager.rotationSnaps([]);
                        this.transformerManager.rotationSnapTolerance(0);
                    }
                }
            })

            this.transformerManager.on("transformend", (e) => {
                if (this.editor.mode === "select") {
                    this.transformerGroup.clearCache();
                    this.transformerGroup.find('.table, .row').forEach((node) => {
                        if (node.name() === "row") {
                            node.find('.seat').forEach(seat => {
                                seat.rotation(-node.rotation() - this.transformerGroup.rotation());
                            });
                            this.positionRowNumbers(node, this.transformerGroup.rotation());
                        } else {
                            const tableNameText = node.tableNameText;
                            if (tableNameText) {
                                this.updateTableNamePosition(node);
                                tableNameText.show();
                            }
                            node.find('.table-seat').forEach(seat => {
                                seat.rotation(-node.rotation() - this.transformerGroup.rotation());
                            });
                        }
                    });
                    this.transformerGroup.cache();
                }
            })
            window.addEventListener('resize', this.updateDimensions);
        });
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateDimensions);
        // ... other cleanups
    },
}
</script>

<style scoped>
.zoom-buttons {
    position: absolute;
    bottom: 30px;
    right: 30px;
}

.canvas {
    width: 100%;
    height: calc(100% - 48px) !important;
}

#editor {
    height: 100vh !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

div.v-toolbar__content{
    padding-left: 0 !important;
}
</style>